<template>
  <div v-if="isLoaded" class="WishDetail-container">
		<div class="back-top" id="opp-back-top" @click="handleBack">
      <div>
        <ion-icon name="arrow-back-outline" class="big-icon"></ion-icon>
      </div>
      <h2>Back</h2>
    </div>
		<hr class="good-line" />

		<h1 class="wish-name">{{wish.position}}</h1>
    <div class="wish-location">{{companyName}} | {{wish.city}}, {{wish.state}}</div>
    <div class="wish-copy-link">
      <input type="text" :value="wish.link" class="wish-item-link" :id="wish.link" disabled="disabled"/>
      <ion-icon name="copy-outline" class="small-icon" @click="copyLink()"></ion-icon>
    </div>

    <h1 class="wish-name">Requested By</h1>
    <ul class="dashboard-detail-list">
      <WishCandidateCard
        v-for="candidate in candidateData"
        :key="candidate.id"
        :candidate="candidate"
        :wishData="wish"
      />
    </ul>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import getWish from "@/composables/WishList/getWish";
import getWishCandidates from "@/composables/WishList/getWishCandidates";
import WishCandidateCard from "@/components/WishList/wishCandidateCard.vue";

export default {
  props: ["companyName", "companyId", "wishId"],
  components: {
    WishCandidateCard,
  },
  async setup(props) {
    const router = useRouter();
    const wish = ref(null);
    const candidateData = ref(null);
    const isLoaded = ref(false);

    onMounted(async () => {
      await getWish(wish, props.companyId, props.wishId)
      await getWishCandidates(candidateData, props.companyId, props.wishId)
      isLoaded.value = true;
    });
    
    // console.log(candidateData.value)

    const handleBack = () => {
      router.go(-1);
    };

    const copyLink = () => {
      let textArea = document.createElement("textarea");
      textArea.value = wish.value.link;
      // make the textarea out of viewport
      textArea.style.position = "absolute";
      textArea.style.left = "0";
      textArea.style.opacity = 0;
      document.body.appendChild(textArea);
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
        // alert("Copied the text: " + wish.link);
      })
    }

    return {
      isLoaded,
      wish,
      candidateData,
      handleBack,
      copyLink,
    }
  }
}
</script>

<style scoped>
.WishDetail-container{
	width: 90%;
  margin: 3vh auto 5vh auto;
}

.wish-name {
  margin-top: 5vh;
  font-size: 150%;
}

.wish-location{
  font-size: 100%;
  padding: 2vh 0;
}

.wish-copy-link {
  position: relative;
}

.wish-item-link {
  width: 50%;
  height: 3vh;
  overflow: scroll;
  background-color: var(--grey-50);
  border: 1px solid #DDDDDD;
  border-radius: 7px;
}

.small-icon{
  position: absolute;
  top: 0;
  left: 41%;
  font-size: 130%;
  cursor: pointer;
}

.dashboard-detail-list {
  list-style: none;
}
</style>